<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
      <span class="input-group-btn input-group-prepend">
          <button class="btn btn-primary bootstrap-touchspin-down" type="button" @click="decrease">-</button>
      </span>
      <input v-model="iValue" type="text" class="form-control text-center" >
      <span class="input-group-btn input-group-append">
        <button class="btn btn-primary bootstrap-touchspin-up" type="button" @click="increase">+</button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: false,
      type: [String, null],
      default: '',
    },

    value: {
      required: false,
      type: [String, Number],
      default: '',
    },
  },

  computed: {
    iValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },

  methods: {
    increase() {
      this.iValue++
    },

    decrease() {
      this.iValue--
    }
  },
}
</script>

<style scoped>
.input-group {
  width: 200px;
}
.form-control {
  font-weight: 600;
}
</style>